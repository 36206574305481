<template>
  <div class="indexBox">
    <div class="bigImg">
      <swiper v-if="swiperData" ref="mySwiper" :options="swiperOption"> 
        <div class="swiper-slide" v-for="(item,i) in swiperData" :key="i" @click="jumpClick(item.url)">
          <img :src="item.imgUrl" />
        </div>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div v-if="imgData">
        <img @click="jumpClick(imgData.url)" :src="imgData.imgUrl !== '' ? imgData.imgUrl: ''" alt="">
      </div>
    </div>
    <div class="indexTitle">
      <!-- 轻松黄金交易，品鉴愉悦人生 -->
      <img src="../../static/index/qshjjy.png" alt="">
      <!-- <img src="../../static/index/2024D.png" alt="" style="position: relative;top: 58px;left: -310px;"> -->
    </div>
    <div class="craigBox">
      <ul @mouseleave="totalLeave">
        <li @mouseover="mouseOver(1)" 
            @mouseleave="mouseLeave(1)" 
            ref="liFirst"
            :class="controlImg.baozhiImgs ? 'bzBkgImg' : ''">
          <img v-if="!controlImg.baozhiImgs" class="icon" :src='controlImg.baozhiImgs ? baozhiImgs[0] : baozhiImgs[1]' alt="">
          <p v-if="!controlImg.baozhiImgs" :class="controlImg.baozhiImgs ? 'textColor' : ''">
            <span>保值性</span>
            <span>时光流转 价值永存1</span>
          </p>
          <div class="wanzhengText" v-if="controlImg.baozhiImgs" >
            <img src="../../static/index/baozhiIcon.png" alt="">
            <!-- <p>时光流转  价值永存</p>
            <p>从古代起黄金便已开始被当作货币来交易，流传千年经久不衰，他的价值不论国界，不分币种，是全世界公认保值性极高的贵金属。</p> -->
            <img style="width:100%; height: auto; margin-top: 30px" src="../../static/index/aa.png" alt="">
          </div>
          <img class="btmBanner" :class="controlImg.baozhiImgs ? 'OpacityImg' : ''" src="../../static/index/jiaohu/bzBanner.png" alt="">
        </li>
        <li ref="liSecond"
            @mouseover="mouseOver(2)" 
            @mouseleave="mouseLeave(2)"
            :class="controlImg.sxjzImgs ? 'sxjzBkgImg' : ''">
          <img v-if="!controlImg.sxjzImgs" class="icon" :src='controlImg.sxjzImgs ? sxjzImgs[0] : sxjzImgs[1]' alt="">
          <p v-if="!controlImg.sxjzImgs" :class="controlImg.sxjzImgs ? 'textColor' : ''">
            <span>双向机制</span>
            <span>进退自如 无惧牛熊</span>
          </p>
          <div class="wanzhengText" v-if="controlImg.sxjzImgs" >
            <img src="../../static/index/jiaohu/sxjz.png" alt="">
            <!-- <p>进退自如 无惧牛熊</p>
            <p>黄金是可以双向交易的产品，既可以做多也可以做空，选对行情两边皆是机会。因此在国际金融环境毕竟动荡的今天，选择黄金能对冲很多风险。</p> -->
            <img style="width:100%; height: auto; margin-top: 30px" src="../../static/index/dd.png" alt="">
          </div>
          <img class="btmBanner" :class="controlImg.sxjzImgs ? 'OpacityImg' : ''" src="../../static/index/sxjzBanner.png" alt="">
        </li>
        <li ref="liThird"
            @mouseover="mouseOver(3)" 
            @mouseleave="mouseLeave(3)"
            :class="controlImg.sctmImgs ? 'sctmBkgImg' : ''">
          <img v-if="!controlImg.sctmImgs" class="icon" :src='controlImg.sctmImgs ? sctmImgs[0] : sctmImgs[1]' alt="">
          <p v-if="!controlImg.sctmImgs" :class="controlImg.sctmImgs ? 'textColor' : ''">
            <span>市场透明</span>
            <span>所见即所得</span>
          </p>
          <div class="wanzhengText" v-if="controlImg.sctmImgs" >
            <img src="../../static/index/jiaohu/sctm.png" alt="">
            <img style="width:100%; height: auto; margin-top: 30px" src="../../static/index/bb.png" alt="">
          </div>
          <img class="btmBanner" :class="controlImg.sctmImgs ? 'OpacityImg' : ''" src="../../static/index/sctmBanner.png" alt="">
        </li>
        <li ref="liFourth"
            @mouseover="mouseOver(4)" 
            @mouseleave="mouseLeave(4)"
            :class="controlImg.jylhImgs ? 'jylhkgImg' : ''">
          <img v-if="!controlImg.jylhImgs" class="icon" :src='controlImg.jylhImgs ? jylhImgs[0] : jylhImgs[1]' alt="">
          <p v-if="!controlImg.jylhImgs" :class="controlImg.jylhImgs ? 'textColor' : ''">
            <span>交易灵活</span>
            <span>随时随地 皆可操作</span>
          </p>
          <div class="wanzhengText" v-if="controlImg.jylhImgs" >
            <img src="../../static/index/jiaohu/jylh.png" alt="">
            <img style="width:100%; height: auto; margin-top: 30px" src="../../static/index/cc.png" alt="">
          </div>
          <img class="btmBanner" :class="controlImg.jylhImgs ? 'OpacityImg' : ''" src="../../static/index/jylhBanner.png" alt="">
        </li>
      </ul>
    </div>
    <div class="indexTitle">
      <!-- 专注黄金交易，一站式管家服务 -->
      <img src="../../static/index/zzhjjy.png" alt="">
    </div>
    <div class="serveBox">
      <div class="sbControl">
        <ul @mouseover="tabClick(1)"
            :class="controlDiv === 1 ? 'ulBottom' : ''">
          <li>
            <i :class="controlDiv === 1 ? 'svgColor' : ''" 
               class="iconfont icon-zhuanyequanwei"></i>
            <span>黃金交易平台</span>
          </li>
          <li>香港贸易场081行员</li>
        </ul>
        <ul @mouseover="tabClick(2)"
            :class="controlDiv === 2 ? 'ulBottom' : ''">
          <li>
            <i :class="controlDiv === 2 ? 'svgColor' : ''" 
               class="iconfont icon-49"></i>
            <span>资金安全</span>
          </li>
          <li>出金自由</li>
        </ul>
        <ul @mouseover="tabClick(3)"
            :class="controlDiv === 3 ? 'ulBottom' : ''">
          <li>
            <i :class="controlDiv === 3 ? 'svgColor' : ''" 
               class="iconfont icon-rencaifuwu"></i>
            <span>服务专业</span>
          </li>
          <li>7*24小时客服</li>
        </ul>
        <ul @mouseover="tabClick(4)"
            :class="controlDiv === 4 ? 'ulBottom' : ''">
          <li>
            <i :class="controlDiv === 4 ? 'svgColor' : ''" 
               class="iconfont icon-huodongliwu"></i>
            <span>丰富活动</span>
          </li>
          <li>点差低，活动优惠</li>
        </ul>
        <ul @mouseover="tabClick(5)"
            :class="controlDiv === 5 ? 'ulBottom' : ''">
          <li>
            <i :class="controlDiv === 5 ? 'svgColor' : ''" 
               class="iconfont icon-gongyouyunzhujishenqingliucheng-02"></i>
            <span>强大软件</span>
          </li>
          <li>全新智能软件化</li>
        </ul>
      </div>
      <div class="sbShow">
        <div class="showChild" v-if="controlDiv === 1">
          <img src="../../static/index/jiaohu/floorBanner.png" alt="">
          <div>
            <!-- 红狮集团，获香港贸易场监管，属贸易场AA类行员<br/>
            多次获得“最佳贵金属交易平台金企” -->
            <img src="../../static/index/craigOne.png?v=2" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 2">
          <img src="../../static/index/jiaohu/anquan.png" alt="">
          <div>
            <!-- 出入金没有额外规则限制，可以随时出金，最快只<br/>
            需3s即可出金 -->
            <img src="../../static/index/craigTwo.png?v=4"  alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 3">
          <img src="../../static/index/jiaohu/fuwu.png" alt="">
          <div>
            <img src="../../static/index/craigThree.png" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 4">
          <img src="../../static/index/jiaohu/huodong.png" alt="">
          <div>
            <img src="../../static/index/craigFour.png" alt="">
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 5">
          <img src="../../static/index/jiaohu/ruanjian.png" alt="">
          <div>
            <img src="../../static/index/craigFive.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="webSocket" :class="websocketScrollTop ? 'fixedWebSocket':''">
      <div class="wssBox">
        <ul>
          <li class="liFirst">
            <span>伦敦金</span>
            <img src="../../static/index/ldj.png" alt="">
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPic ? 'classRed' : 'classGreen' ">{{London.gold}}</span>
              <img :src="London.controlPic ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
            <p>
              <span>{{London.goldRise}}</span>
              <span>{{London.goldPer}}</span>
            </p>
          </li>
        </ul>
        <ul>
          <li class="liFirst">
            <span>伦敦银</span>
            <img src="../../static/index/ldy.png" alt="">
          </li>
          <li class="liSecond">
            <p>
              <span :class="London.controlPicOther ? 'classRed' : 'classGreen' ">{{London.silver}}</span>
              <img :src="London.controlPicOther ? LondonImg[0] : LondonImg[1]" alt="">
            </p>
            <p>
              <span>{{London.silverRise}}</span>
              <span>{{London.silverPer}}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="Float" v-if="false" @click="jumpActivity">
      <img @click.stop="()=>{controlFloat = false}" src="../../static/index/closer2.png" alt="">
      <div class="floatBox" v-if="openFloat">
         <ul class="codeBox">
              <li>
                <img src="../../static/index/iosCode.png" alt="">
                <span>App Store</span>
              </li>
              <li>
                <img src="../../static/index/androidcode.png?v=2" alt="">
                <span>Android</span>
              </li>
            </ul>
      </div>
    </div>
    <!-- 新年装饰3 -->
    <!-- <div class="dark" v-if="controlDark">
       <canvas id="canvasParticle" style="position:relative;z-index:3;" width="1920" height="1000">Canvas is not supported in your brower.</canvas>
      <img src="../../static/index/door.png" alt="">
      <img class="closer3" style="z-index:4;" @click="()=>{controlDark = false}" src="../../static/index/closer3.png" alt="">
    </div> -->
    <!-- <img class="lanternRight" src="../../static/index/lanternRight.png" alt="">
    <img class="lanternleft" src="../../static/index/lanternLeft.png" alt=""> -->
  </div>
</template>
<script>
  import { base64ToFloat32 } from '../../utils/tools';
  import { analyticalData } from '../../utils/tools';
  import { quote,quoteTime } from '../../api/info';
  import {maidian} from '../global'
  import {swiperBanner} from '../../api/info'
  export default {
    name: 'Index',
    metaInfo: {  
      title: '红狮金业官网,黄金交易平台,红狮启富,持牌正规安全首选红狮金业',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易,贵金属投资,贵金属平台,现货黄金开户,正规靠谱外汇交易,正规网上交易平台。' },  
        { name: 'description', content: '红狮金业是专业值得信赖的国际投资交易平台，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
    data() {
      return {
        openFloat:false,
        pic: [require('../../static/index/zs-1.png'), require('../../static/index/zs-2.png'),require('../../static/index/zs-3.png'),require('../../static/index/zs-4.png'),require('../../static/index/zs-5.png')],
        baozhiImgs:[
          require('../../static/index/baozhiIcon.png'),
          require('../../static/index/jiaohu/bzx.png'),
        ],
        sxjzImgs:[
          require('../../static/index/jiaohu/sxjz.png'),
          require('../../static/index/sxjzIcon.png'),
        ],
        sctmImgs:[
          require('../../static/index/jiaohu/sctm.png'),
          require('../../static/index/sctmIcon.png'),
        ],
        jylhImgs:[
          require('../../static/index/jiaohu/jylh.png'),
          require('../../static/index/jylhIcon.png'),
        ],
        LondonImg:[
          require('../../static/index/jiaohu/up.png'),
          require('../../static/index/jiaohu/down.png'),
        ],
        selectedImg: require('../../static/index/yuandian.png'),
        controlSwiper: true,
        // swiper 参数
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          grabCursor:true,
          loop: true,
          preventClicks : true,
           //自动播放
          autoplay: {
            delay: 3000, //延迟播放下一张图片
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            // dynamicBullets: true,
            // type:'custom',
            // renderCustom: function (swiper, current, total) {
            //   if(current === 1){
            //     return '<span class="swiperOne"><li><span></span></li><span class="swiperOneSpan"></span><span></span></span>';
            //   }else if(current === 2){
            //     return `<span class="swiperTwo"><span></span><li><span></span></li><span></span></span>`;
            //   }else{
            //     return `<span class="swiperThree"><span></span><span class="swiperThreeSpan"></span><li><span></span></li></span>`;
            //   }
            // }
          },
        },
        // 控制craigBox
        controlImg:{
          baozhiImgs: true,
          sxjzImgs: false,
          sctmImgs: false,
          jylhImgs: false,
        },
        // 控制serveBox
        controlDiv:1,
        // 控制websocket模块是否定位
        websocketScrollTop:false,
        craigCao: 0,
        London:{
          gold: '--',
          silver: '--',
          goldRise: '0.0',
          silverRise: '0.0',
          goldPer: '0.00%',
          silverPer: '0.00%',
          controlPic: true,
          controlPicOther: true,
        },
        goldCl:'',
        silverCl:'',
        ahref:'https://www.rlcltd001.com/#/subject/worldCup/index?craig=' + window.location.origin,
        controlFloat:true,
        controlDark:true,
        swiperData:'',
        imgData:'',
      }
    },
    created(){
      // 自动切换serveBox
      setInterval(this.updateDiv, 4000);
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
      // crm数据埋点
      maidian({ eventname: 'z1', eventdescribe: '访客数据', eventtype: 'visit' },undefined,{toPath:'www.123.com',fromPath:'www.baidu.com'},'indexMaidian');
      this.initSwiper();
    },
    mounted() {
       this.partical()
      setTimeout(() => {
        this.controlDark = false;
      }, 5000); // 4 秒后关闭弹窗
      // 初始化websocket
      // this.initWebSocket('wss://tradeapi-pm-hq3.mcitj.com:4433/quote');
      window.addEventListener("scroll", this.handleScroll);

      // 初始化昨收
      this.initYesterday({goods: "GOLD,SILVER"});
      // 初始化实时行情
      setInterval(()=>{
        // this.initRealtime({goods: "GOLD,SILVER"});
      },1000)
    },
    methods: {
      partical() {
      var canvas = document.getElementById('canvasParticle')
      const W = window.innerWidth
      const H = 1000 // window.innerHeight
      var ctx = null
      canvas.setAttribute('width', W)
      canvas.setAttribute('height', H)
      ctx = canvas.getContext('2d')
      const config = {
        number: 30, // 生成的粒子数量
        snowArr: [],
        pic: this.pic
      }
      for (let i = 0; i < config.number; i++) {
        config.snowArr.push({
          x: Math.random() * W, // 定义每片粒子的X轴
          y: Math.random() * H, // Y轴
          toX: Math.random() * 1 + 3, // 粒子每一次渲染的X距离
          toY: Math.random() * 1 + 3,
          size: Math.random() * 100 + 5 // 粒子的大小
        })
      }
      const dropAnimation = () => {
        ctx.clearRect(0, 0, W, H) // 清除画布重新渲染
        let j = 0
        for (let i = 0; i < config.snowArr.length; i++) {
          const snow = config.snowArr[i]
          const snowImg = new Image()
          if (j > 24) {
            j = 0
          }
          snowImg.src = config.pic[j]
          ctx.drawImage(snowImg, snow.x, snow.y, snow.size, snow.size)
          snow.x = snow.x > W ? 0 : snow.x + snow.toX // 每调一次函数向右移动一点
          snow.y = snow.y > H ? 0 : snow.y + snow.toY // 向下移动
          j++
        }
        requestAnimationFrame(dropAnimation)
      }
      requestAnimationFrame(dropAnimation)
    },
      // 获取swiper接口
      initSwiper(){
        const option = {
          languageidentification:1,
          type:58,
          // type:34,
          region:1
        }
        swiperBanner(option).then(res=>{
          if(res.Status === 0){
            if(res.Data.length>1){
              this.swiperData = res.Data;
            }else{
              this.imgData = res.Data[0];
            }
          }
        }).catch(err=>{
          console.log(err);
        })
      },
      // 获取昨收
      initYesterday(attr){
        quote(attr).then(res=>{
          this.goldCl = res.dt.list[0].close;
          this.silverCl = res.dt.list[1].close;
        })
      },
      initRealtime(attr){
        quoteTime(attr).then(res=>{
          this.London.gold = res.dt.list[0].bid;
          this.London.silver = res.dt.list[1].bid;
          // 黄金 实时与昨收比较大小 决定显示图片
          if(Number(this.goldCl) < Number(this.London.gold)){
            this.London.controlPic = true;
          }else{
            this.London.controlPic = false;
          }
          // 白银 实时与昨收比较大小 决定显示图片
          if(Number(this.silverCl) < Number(this.London.silver)){
            this.London.controlPicOther = true;
          }else{
            this.London.controlPicOther = false;
          }
          // 黄金 计算涨幅;
          this.London.goldRise = (Number(this.London.gold) - Number(this.goldCl)).toFixed(2);
          // 计算百分比（涨幅/昨收）
          this.London.goldPer = ((this.London.goldRise/this.goldCl)*100).toFixed(2) + '%';
          // 白银 涨幅
          this.London.silverRise =  (Number(this.London.silver) - Number(this.silverCl)).toFixed(3);
          // 计算百分比（涨幅/昨收）
          this.London.silverPer = ((this.London.silverRise/this.silverCl)*100).toFixed(2) + '%';
        })
      },
      handleScroll () { //改变元素#searchBar的top值
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if(scrollTop > 1000){
          this.websocketScrollTop = true;
        }else{
          this.websocketScrollTop = false;
        }
      },
      // 切换serveBox的方法
      updateDiv(){
        if(this.controlDiv < 5){
          this.controlDiv = this.controlDiv + 1;
        }else{
          this.controlDiv = 1;
        } 
      },
      // websocket 方法
      initWebSocket(src) {
        const wsuri = src;
        // this.websock = new WebSocket(wsuri);
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onclose = this.websocketclose;
      },
      websocketonopen() {
        let actions = { 't': 7, 'd': { 'k': 0 }};
        this.websocketsend(JSON.stringify(actions));
      },
      websocketonerror() {
        let _this = this;
        console.log("连接建立失败");
        // this.initWebSocket('wss://tradeapi-pm-hq2.mcrubly.com:4433/quote');
      },
      websocketonmessage(e) {
        const data = JSON.parse(e.data)
        if (data.t === 0) {
          for (const iterator of data.d) {
            const blob = window.atob(iterator.q)
            const symbol = analyticalData(data.d[0].q||"",5);
            // console.log(symbol);
            if(symbol.SymbolName.slice(0,4) == 'GOLD'){
              // 黄金
              this.London.gold = symbol.Bid.toFixed(3);
              let gold = symbol.Bid;
              if(Number(gold) > Number(this.London.gold)){
                this.London.controlPic = true;
              }else{
                this.London.controlPic = false;
              }
            }else if(symbol.SymbolName.slice(0,6) == 'SILVER'){
              // 白银
              this.London.silver = symbol.Bid.toFixed(3);
              let silver = symbol.Bid;
              if(Number(silver) > Number(this.London.silver)){
                this.London.controlPicOther = true;
              }else{
                this.London.controlPicOther = false;
              }
            }
          }
        }else if (data.t === 5) {
          console.log('t=5');
          let open = data.d.filter(item => {
            if (/(GOLD|SILVER)/.test(item.n)) {
              return item.n
            }
          })
          open.forEach(element => {
            if(element.n == 'GOLD'){
              // 黄金
              this.goldCl =  element.cl;
            }else if(element.n == 'SILVER'){
              // 白银
              this.silverCl = element.cl;
            }
          });
        }
        // 黄金 计算涨幅;
        this.London.goldRise = (Number(this.London.gold) - Number(this.goldCl)).toFixed(2);
        // 计算百分比（涨幅/昨收）
        this.London.goldPer = ((this.London.goldRise/this.goldCl)*100).toFixed(2)+ '%';
        // 白银 涨幅
        this.London.silverRise =  (Number(this.London.silver) - Number(this.silverCl)).toFixed(3);
        // 计算百分比（涨幅/昨收）
        this.London.silverPer = ((this.London.silverRise/this.silverCl)*100).toFixed(2)+ '%';
      },
      websocketsend(Data) {
        this.websock.send(Data);
      },
      websocketclose(e) {
        console.log("断开连接", e);
      },
      // 鼠标移入事件
      mouseOver(attr){
        switch (attr) {
          case 1:
            this.controlImg.baozhiImgs = true;
            break;
          case 2:
            this.controlImg.sxjzImgs = true;
            this.controlImg.baozhiImgs = false;
            break;
          case 3:
            this.controlImg.sctmImgs = true;
            this.controlImg.baozhiImgs = false;
            break;
          case 4:
            this.controlImg.jylhImgs = true;
            this.controlImg.baozhiImgs = false;
            break;
        }
      },
      // 鼠标移出事件
      mouseLeave(attr){
        switch(attr){
          case 1:
            this.controlImg.baozhiImgs = false;
            break;
          case 2:
            this.controlImg.sxjzImgs = false;
            break;
          case 3:
            this.controlImg.sctmImgs = false;
            break;
          case 4:
            this.controlImg.jylhImgs = false;
            break;
        }
      },
      // 鼠标移出整个box时，设置默认展开栏
      totalLeave(){
        this.controlImg.baozhiImgs = true;
      },
      // tabbar点击事件
      tabClick(attr){
        switch(attr){
          case 1:
            this.controlDiv = attr;
            break;
          case 2:
            this.controlDiv = attr;
            break;
          case 3:
            this.controlDiv = attr;
            break;
          case 4:
            this.controlDiv = attr;
            break;
          case 5:
            this.controlDiv = attr;
            break;
        }
      },
      // 点击跳转到专题页
      // jumpClick(attr){
      //   if(attr === 1){
      //     window.location.href = window.location.origin + '/subject/chj_2112';
      //   }else if (attr === 2) {
      //     // this.$router.push({
      //     //   name:'startPc',
      //     // })
      //     window.open('https://www.rlcvipltd.net/#/subject/newYear/index');
      //   } else if (attr === 3) {
      //     window.open('https://www.rlcvipltd.net/#/subject/system/index');
      //   }
      // },
      jumpClick(attr){
        window.open(attr);
      },
      jumpActivity(){
        //window.open('https://www.rlcvipltd.net/uc/signUp/real');
        this.openFloat = true;
      }
    },
    computed: {
      //计算高度
      computeHeight() {
        let screenHeight = document.body.scrollTop;
        console.log(screenHeight);
        if (screenHeight > 415) {
          return '550px';
        } else {
          return '250px';
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .indexBox{
    width: 100%;
    .bigImg{
      width: 100%;
      height: 500px;
      background: url('../../static/index/banner.png') no-repeat;
      background-size: cover;
      /deep/ .swiper-container{
        .swiper-slide{
          img{
            width: 1920px;
            height: 500px;
            object-fit: cover;
          }
        }
        .swiper-pagination{
          .swiper-pagination-bullet-active{
            background-color: #e1352a;
          }
        }
      }
    }
    .indexTitle{
      font-size: 28px;
      color: #121212;
      box-sizing: border-box;
      padding: 35px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: url('../../static/index/indexTitleText.png') no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
    .craigBox{
      width: 100%;
      box-sizing: border-box;
      padding: 60px 0;
      background: url('../../static/index/changeBanner.png') no-repeat;
      background-size: cover;
      ul{
        width: 1130px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        li{
          width: 25%;
          height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          box-sizing: border-box;
          border-right: 5px solid #f8f8f8;
          padding: 80px 0 0 70px;
          position: relative;
          background-color: #fff;
          .btmBanner{
            position: absolute;
            right: 0;
            bottom: 0;
          }
          p{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            span{
              font-size: 14px;
              color: #6d6d6d;
            }
            span:nth-child(1){
              font-size: 16px;
              font-weight: 600;
              color: #121212;
            }
          }
        }
        .wanzhengText{
          width: 65%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end !important;
          align-items: flex-start;
          p{
            color: #fff;
            margin: 0;
            text-align: justify;
            line-height: 26px;
          }
          p:nth-child(2){
            font-size: 22px;
            color: #fff;
            margin: 30px 0 10px;
          }
        }
        .bzBkgImg{
          background: url('../../static/index/baozhiBanner.png') no-repeat;
          background-size: cover;
          width: 45%;
        }
        .sxjzBkgImg{
          background: url('../../static/index/jiaohu/jzBanner.png') no-repeat;
          background-size: cover;
          width: 45%;
        }
        .sctmBkgImg{
          background: url('../../static/index/jiaohu/scBanner.png') no-repeat;
          background-size: cover;
          width: 45%;
        }
        .jylhkgImg{
          background: url('../../static/index/jiaohu/jyBanner.png') no-repeat;
          background-size: cover;
          width: 45%;
        }
        li .textColor span{
          color: #fff !important;
        }
        li .OpacityImg{
          display: none;
        }
      }
    }
    .serveBox{
      width: 100%;
      .sbControl{
        width: 1130px;
        height: 110px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #e2e2e2;
        .ulBottom{
          border-bottom: 4px solid #d52226;
        }
        ul{
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;
          li{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            font-size: 15px;
            color: #6d6d6d;
            margin-bottom: 10px;
            .svgColor{
              color: #d52226;
            }
            span{
              font-size: 18px;
              color: #121212;
              margin-left: 10px;
            }
          }
        }
      }
      .sbShow{
        width: 100%;
        .showChild{
          width: 100%;
          height: 402px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 50%;
            margin-top: 25px;
          }
          div{
            width: 50%;
            // min-width: 950px;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 30px;
            color: #121212;
            background: url('../../static/index/jiaohu/textBanner.png') no-repeat;
            background-size: cover;
          }
        }
      }
    }
    .webSocket{
      width: 100%;
      height: 140px;
      background: url('../../static/index/ldjyBanner.png') no-repeat;
      background-size: cover;
      position: fixed;
      bottom: 0;
      display: none;
      .wssBox{
        width: 60%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .liFirst{
            span{
              font-size: 19px;
              color: #121212;
            }
          }
          .liSecond{
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: 70px;
            p{
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0px;
              span{
                font-size: 18px;
              }
              .classRed{
                color: #d52226;
              }
              .classGreen{
                color: #18a450;
              }
              img{
                width: 18px;
                height: auto;
                margin-left: 10px;
              }
            }
            p:nth-child(2){
              margin-bottom: 10px;
              span{
                font-size: 14px;
                color: #6d6d6d;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .Float{
      width: 230px;
      height: 360px;
      background: url('../../static/index/float.png') no-repeat;
      background-size: cover;
      position: fixed;
      top: 39%;
      right: -6.0%;
      transform: translate(-50%,-50%);
      z-index: 2;
      cursor: pointer;
      img{
        width: 28px;
        position: absolute;
        top: -3%;
        right: 1px;
        cursor: pointer;
      }
    }
    .dark{
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
       background-color: rgba(0, 0, 0, 0.5); 
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
         opacity: 1;
      position: relative;
      left: -50%;
      }
      .closer3{
         width: 30px;
     position: relative;
      top: -260px;
    right: 0%;
      cursor: pointer;
      }
    }
    .lanternRight{
      width: 89px;
      position: fixed;
      top: 50%;
      right: 7%;
      z-index: 2;
      transform: translate(-50%,-50%);
    }
    .lanternleft{
      width: 89px;
      position: fixed;
      top: 50%;
      left: 10%;
      z-index: 2;
      transform: translate(-50%,-50%);
    }
    .fixedWebSocket{
      position: inherit;
    } 
  }
  /deep/ #box{
    margin-top: 100px;
  }
  
  // 设置swiper分页器的样式
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: 55px;
  }
  /deep/ .swiperOne{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
    .swiperOneSpan{
      margin: 0 30px;
    }
  }
  /deep/ .swiperTwo{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 30px;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
  }
  /deep/ .swiperThree{
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      width: 4px;
      height: 4px;
      background-color: #999999;
      border-radius: 50%;
    }
    li{
      width: 20px;
      height: 20px;
      border: 2px solid #d52226;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        width: 4px;
        height: 4px;
        background-color: #d52226;
        border-radius: 50%;
      }
    }
    .swiperThreeSpan{
      margin: 0 30px;
    }
  }
  .floatBox{
    position: relative;
    left: -176px;
    ul{
      width: 275px;
      position: absolute;
      top:50px;
      left: -100px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background-color: #ffffff;
      box-sizing: border-box;
      padding: 20px 30px 20px;
      border-radius: 10px;
      box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
     
      li{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width:100px!important;
          height: auto!important;
          position: unset!important;
          display: block;
        }
        span{
          margin-top: 5px;
          color: #000;
          line-height: 29px;
        }
      }
      li:nth-child(1){
        margin-right: 55px;
      }
    }
  }
  .codeBox{
        display: flex;
      }
</style>